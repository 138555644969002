.card {
  border: 1px dashed white;
  border-radius: 10%;
  width: 200px;
  padding: 8px;
  margin: 8px;
  background-color: #f42069;
  color: rgb(235, 232, 232);
  position: relative;

  h2 {
    text-decoration: underline;
    margin: 4px;
    display: inline-block;
  }

  span {
    position: absolute;
    right: 5%;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      color: darkslategrey;
    }
  }

  p {
    margin: 4px;
  }

  img {
    width: 100%;
    border-radius: 10%;
    margin: auto;
    border: 1px solid white;
    display: block;
  }
}
